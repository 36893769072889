// This is the main component for the task item
// wraps TaskItemMDXEditor and TaskItemViewer
// and conditionally renders one of them based on the isActive flag

import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor';
import { TaskItemViewer } from '../TaskItemViewer/TaskItemViewer';
import { useTaskItemStore } from '../../stores/useTaskItemStore';
import { uuid } from '../../types/types';

import styles from './TaskItem.module.css';

import '@mdxeditor/editor/style.css';
import './prose.css';
import { Box, Button, Flex, IconButton, Popover } from '@radix-ui/themes';
import * as RadixPrimitivePopover from '@radix-ui/react-popover';
import { consoleLog } from '../../utilities/console';
import { getColor } from '../MultipleContainers/MultipleContainers';
// import { CheckIcon } from '@radix-ui/react-icons';

type TaskItemProps = {
  id: uuid;
  // isActive?: boolean;
  defaultValue: string;
};

export const TaskItem = React.memo(({ id, defaultValue }: TaskItemProps) => {
  const item = useTaskItemStore((s) => s.items)[id];
  const activeItemId = useTaskItemStore((s) => s.activeItemId);
  const isGlobalReadOnly = useTaskItemStore((s) => s.isGlobalReadOnly);

  const _updateContent = useTaskItemStore((s) => s.updateContent);
  const _setActiveItemId = useTaskItemStore((s) => s.setActiveItemId);

  // Think twice before removing me!
  // Rational: isEditing is a local state. We don't want all items to get updated when one item is editing.
  const [isEditing, setIsEditing] = useState(false);

  const updateContent = useCallback(
    (markdown: string) => _updateContent(id, markdown),
    [id]
  );
  const setActiveItemId = useCallback(() => _setActiveItemId(id), [id]);

  const content = useMemo(
    () => item?.content || defaultValue,
    [item?.content, defaultValue]
  );

  const isEditorActive = activeItemId === id && !isGlobalReadOnly;

  const editorMemoized = useMemo(() => {
    if (!isEditorActive) {
      return null;
    }
    return <TaskItemMDXEditor
      value={content}
      placeholder={defaultValue}
      updateContent={updateContent}
      updateIsEditing={setIsEditing}
    />
  }, [isEditorActive]);

  // const defaultHeight = 'auto';
  // const [height, setHeight] = useState(defaultHeight);
  const refContainer = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);
  const refAnchor = useRef<HTMLDivElement>(null);
  const refAnchorDistanceToBottom = useRef<number>(0);

  // The popover byh default aligns to the top edge of the viewer.
  // When the popover is close to the bottom edge of the screen,
  // the popover will position itself above the viewer.
  // Need to move it down to cover the viewer. 
  useEffect(() => {
    if (refAnchor.current) {
      const distanceToBottom = refAnchor.current.getBoundingClientRect().bottom;
      // find the parent dom of a `ul` with classname ends with `containerList`
      const containerDistanceToBottom = refAnchor.current.closest('ul[class*="containerList"]')
        ?.getBoundingClientRect().bottom || window.innerHeight;

      refAnchorDistanceToBottom.current = containerDistanceToBottom - distanceToBottom;
    }
  });

  // update height of the container based on the content height
  // useEffect(() => {
  //   if (refContent.current) {
  //     const contentHeight = `${refContent.current.scrollHeight}px`;
  //     setHeight(isEditing ? defaultHeight : contentHeight);
  //   }
  // }, [activeItemId, isEditing]);

  useEffect(() => {
    if (activeItemId === id) {
      setIsEditing(true);
    }
  }, [activeItemId]);


  consoleLog('item', item)
  const backgroundColor = getColor(item?.keys?.priority);

  /// Active: current active item, should render TaskItemMDXEditor
  /// Idle: all other items, should render TaskItemViewer
  return (
    <Popover.Root onOpenChange={(open) => {
      if (open) {
        setActiveItemId()
      } else {
        _setActiveItemId(null);
      }
    }}>

      <div
        ref={refContainer}
        className={`${styles.container}`}
        style={{
          // height, 
          backgroundColor
        }}
      >

        <div ref={refContent}>

          <Popover.Trigger>
            <Button variant="soft" className={styles.setActiveButton}>
              {' '}
            </Button>
          </Popover.Trigger>

          <Popover.Content
            sideOffset={0}
            alignOffset={0}
            className={styles.popoverContent}
            style={{
              '--data-distance-to-bottom': `${refAnchorDistanceToBottom.current}px`,
            } as React.CSSProperties}
          >
            <Flex gap="3">
              <Box flexGrow="1">

                {/* {activeItemId === id && !isGlobalReadOnly ? (
                  <div className={styles.editorContainer}>
                    <TaskItemMDXEditor
                      value={content}
                      placeholder={defaultValue}
                      updateContent={updateContent}
                      updateIsEditing={setIsEditing}
                    />
                  </div>
                ) : null} */}
                {editorMemoized}

                <Box p="3" className={styles.saveButtonContainer}>
                  {/* <Flex align="center" gap="2" asChild>
                    <Text as="label" size="2">
                      <Checkbox />
                      <Text>Send to group</Text>
                    </Text>
                  </Flex> */}
                  <Popover.Close>
                    <Button
                      size="1"
                      color="gray"
                      variant="outline"
                      highContrast
                    >
                      {/* <CheckIcon /> */}
                      Save
                    </Button>
                  </Popover.Close>
                </Box>
              </Box>
            </Flex>
          </Popover.Content>

          <RadixPrimitivePopover.Anchor ref={refAnchor}></RadixPrimitivePopover.Anchor>

          <TaskItemViewer
            itemId={id}
            value={content}
            placeholder={defaultValue}
            setActive={setActiveItemId}
          />

        </div>
      </div>
    </Popover.Root>

  );
});
