import { useState } from 'react';
// import * as Popover from '@radix-ui/react-popover';
import { Button, Popover } from '@radix-ui/themes';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';

import styles from './EmojiPicker.module.css';
import classNames from 'classnames';
import { useTaskListStore } from '../../stores/useTaskListStore';
import { uuid } from '../../types/types';

const CustomEmojiPicker = ({ id, emoji }: { id?: uuid; emoji?: string }) => {
  const updateIcon = useTaskListStore((s) => s.updateIcon);
  const [selectedEmoji, setSelectedEmoji] = useState(emoji || '😶');
  const [isIconPickerOpen, setIsIconPickerOpen] = useState<boolean | null>(null);

  const forceClose = {
    open: isIconPickerOpen === false ? false : undefined,
  }

  return (
    <Popover.Root {...forceClose} >
      <Popover.Trigger>
        <Button
          variant="soft"
          className={classNames(styles.EmojiButton, {
            [styles.isFocused]: isIconPickerOpen,
          })}
          onClick={() => setIsIconPickerOpen(true)}
        >
          {selectedEmoji}
        </Button>
      </Popover.Trigger>
      <Popover.Content className={styles.popoverContent}>
        <EmojiPicker
          searchDisabled={true}
          emojiStyle={EmojiStyle.APPLE}
          lazyLoadEmojis={true}
          width={270}
          onEmojiClick={(emojiData) => {
            setIsIconPickerOpen(false);
            setSelectedEmoji(emojiData.emoji);

            // Only update if id is provided
            if (id) {
              updateIcon(id, emojiData.emoji);
            }
          }}
        />
      </Popover.Content>
    </Popover.Root>
  );
};

export default CustomEmojiPicker;
