// Init a zustand store for user config and settings

import create from 'zustand';

type UserConfig = {
  theme: string;
  language: string;
  notificationsEnabled: boolean;
  serverTime: Date;
}

type UserAction = {
  setTheme: (newTheme: string) => void;
  setLanguage: (newLanguage: string) => void;
  toggleNotifications: () => void;
}

const useUserConfigStore = create<UserConfig & UserAction>((set) => ({
  // Initial state
  theme: 'light', // Example setting
  language: 'en', // Example setting
  notificationsEnabled: true, // Example setting
  serverTime: new Date(), // TODO: change to server side time

  // Actions
  setTheme: (newTheme: string) => set({ theme: newTheme }),
  setLanguage: (newLanguage: string) => set({ language: newLanguage }),
  toggleNotifications: () => set((state: { notificationsEnabled: boolean }) => ({ notificationsEnabled: !state.notificationsEnabled })),
}));

export default useUserConfigStore;
