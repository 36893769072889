import React, { useEffect } from 'react';
import classNames from 'classnames';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';

import { Handle, Remove, Plus } from './components';

import styles from './Item.module.scss';
import { AlertDialog, Box, Button, DropdownMenu, Flex, IconButton, Popover, Separator, Text } from '@radix-ui/themes';

import { TrashIcon, CheckCircledIcon, ArchiveIcon } from '@radix-ui/react-icons'
import { useTaskListStore } from '../../stores/useTaskListStore';
import { uuid } from '../../types/types';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  plus?: boolean; // added plus button
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;

  onRemove?(): void;
  onClick?(): void;

  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }): React.ReactElement;
}

// const RemoveButton = ({ onClick }: { onClick: () => void }) => (
//   <Remove className={styles.Remove} onClick={onClick} />
// );


const MenuButton = ({ onRemove }: { onRemove?: () => void }) => {

  return (
    <>
      <AlertDialog.Root>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="soft" className={styles.addNewItemDropdownTrigger}>
              <DropdownMenu.TriggerIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">

            <DropdownMenu.Item color="green">
              {/* TODO: add complete task */}
              <CheckCircledIcon /><Text>Complete</Text>

            </DropdownMenu.Item>

            <DropdownMenu.Separator />

            <DropdownMenu.Item
            // shortcut="⌘ E"
            >
              {/* TODO: add complete task */}
              <ArchiveIcon /><Text>Archive</Text>

            </DropdownMenu.Item>

            <AlertDialog.Trigger>
              <DropdownMenu.Item
                className={styles.removeItemItem}
                color="red"
              // shortcut="⌘ E"
              >
                <Flex className={styles.removeItemTrigger}
                >
                  <TrashIcon /><Text>Remove</Text>
                </Flex>
              </DropdownMenu.Item>
            </AlertDialog.Trigger>

          </DropdownMenu.Content>
        </DropdownMenu.Root>




        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Remove a Task</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure? This Task will no longer exist once removed.
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel, keep it
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button variant="solid" color="red"
                onClick={() => {
                  onRemove?.()
                }}
              >
                Remove it
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>

      </AlertDialog.Root>
    </>
  )
}

// const SaveEditButton = ({ onSave }: { onSave?: () => void }) => (
//   <IconButton>
//     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg>
//   </IconButton>
// )

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        plus,
        height,
        index,
        listeners,
        onRemove,
        onClick,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      // listeners = {
      //   ...listeners,
      //   onMouseUp: () => {
      //     // 
      //     consoleLog('Item clicked');
      //   }
      // }

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
          ref={ref}
          {...(onClick && { onClick })}
        >
          {/* TODO: add header for items when necessary */}
          {/* <span className={styles.ItemHeader}>
            Start on {new Date().toLocaleTimeString()}
          </span> */}

          <Flex>
            <Box className={styles.Actions}>
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </Box>
            <Box
              className={classNames(
                styles.Item,
                dragging && styles.dragging,
                handle && styles.withHandle,
                plus && styles.withPlus,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
                color && styles.color
              )}
              style={style}
              data-cypress="draggable-item"
              {...(!handle ? listeners : undefined)}
              {...props}
              tabIndex={!handle ? 0 : undefined}
            >
              {value}
            </Box>
            <Box className={styles.Actions}>
              <MenuButton onRemove={onRemove} />
            </Box>
          </Flex>
        </li >
      );
    }
  )
);
