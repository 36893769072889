import { uuid } from '../types/types';
import { create } from 'zustand';
import { createUuid } from '../utilities';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import type { } from '@redux-devtools/extension';
import type { KV } from '../types/types';
// Each `List` has its own state, and contains multiple `Item`s
export type List = {
  id: uuid;
  keys?: KV; // an map of strings, internal usage, represents the meaning of this list.
  label?: string; // label will be changed/derived from the date, like Today, Yesterday, Tomorrow, etc.
  icon?: string; // icon like ☀️, 🌙, etc.
  iconMutable?: boolean; // if true, the icon can be changed by user
  createdDate?: string; // Date converted toISOString as local storage can not hold Object
  updatedDate?: string; // Date converted toISOString as local storage can not hold Object
};

export type ListMap = Record<uuid, List>; // listId -> list state
export type ItemsMap = Record<uuid, uuid[]>; // listId -> task item id []

type ListStore = {
  // state
  itemsMap: ItemsMap;
  userListMap: ListMap;
  // userListIds: uuid[];
  taskListMap: ListMap;
  visibleUserListIds: uuid[];

  // derived state
  // computed: {
  //   lists: ListMap
  //   listIds: uuid[]
  // }
  // lists: ListMap
  // listIds: uuid[]
  reset: () => void;

  setItemsMap: (itemsMap: ItemsMap) => void;
  updateItemsMap: (updater: (currentMap: ItemsMap) => ItemsMap) => void; // callback style

  updateVisibleUserListIds: (listIds: uuid[]) => void;

  addUserList: (label: string, key: KV) => uuid;
  removeUserList: (id: uuid) => void;
  removeItemFromItemsMap: (id: uuid, itemId: uuid) => void;

  updateIcon: (id: uuid, icon: string) => void;
  updateListDate: (
    id: uuid,
    updatedDate: Date,
    overwriteCreatedDate?: Date
  ) => void;
};

export const DAY_ZERO_LIST_ID = '1a32607c-46f4-49ad-9752-27b51487f60a';
const IMPORTANT_URGENT_LIST_ID = '223270cb-72e5-43be-959c-d4e1744a96a6';
const URGENT_LIST_ID = '71c4cdf4-0ff3-4722-8069-47a2bb9dd3de';
const IMPORTANT_LIST_ID = 'dc79a944-2a0a-49e5-aa38-19cbca74aabe';
const LOW_PRIORITY_LIST_ID = 'c3565f9e-50e4-4a96-966b-ba95ea2c3292';

// These 5 list ids are reserved and immutable.
const defaultItemsMap: ItemsMap = {
  [IMPORTANT_URGENT_LIST_ID]: [],
  [URGENT_LIST_ID]: [],
  [IMPORTANT_LIST_ID]: [],
  [LOW_PRIORITY_LIST_ID]: [],
  // [DAY_ZERO_LIST_ID]: [],
};

/// Eisenhower Matrix lists
export const EM_ = 'EM_';

export enum PRIORITY {
  EM_IMP_URG = `${EM_}IMP_URG`,
  EM_URG = `${EM_}URG`,
  EM_IMP = `${EM_}IMP`,
  EM_LOW = `${EM_}LOW`,
}

/// Day Key starts with this prefix
export const DAY_ = 'DAY_';

/// In order to get them updated when new version JS is launched.
export const defaultEisenhowerListMap: ListMap = {
  [IMPORTANT_URGENT_LIST_ID]: {
    id: IMPORTANT_URGENT_LIST_ID,
    keys: { priority: PRIORITY.EM_IMP_URG },
    label: 'Important & Urgent',
    icon: '🌊',
  },
  [URGENT_LIST_ID]: {
    id: URGENT_LIST_ID,
    keys: { priority: PRIORITY.EM_URG },
    label: 'Urgent',
    icon: '⚡',
  },
  [IMPORTANT_LIST_ID]: {
    id: IMPORTANT_LIST_ID,
    keys: { priority: PRIORITY.EM_IMP },
    label: 'Important',
    icon: '🌱',
  },
  [LOW_PRIORITY_LIST_ID]: {
    id: LOW_PRIORITY_LIST_ID,
    keys: { priority: PRIORITY.EM_LOW },
    label: 'Options', // What if A's task is put in B's 
    icon: '🐚',
  },
};

/// User Generated Contents should be persisted, upgrade should never touch them.
// const defaultUserListMap: ListMap = {
//   [DAY_ZERO_LIST_ID]: {
//     id: DAY_ZERO_LIST_ID,
//     keys: [`${DAY_}Zero`],
//     label: '',
//     icon: '😶',
//     iconMutable: true,
//   },
// };

// export const getFullListMap = (userListMap: ListMap): ListMap => {
//   return { ...defaultEisenhowerListMap, ...userListMap };
// }

export const getListIds = (itemsMap: ListMap): uuid[] => {
  return Object.keys(itemsMap);
};

const initState = {
  itemsMap: { ...defaultItemsMap },
  userListMap: {},
  taskListMap: { ...defaultEisenhowerListMap },

  // which user lists are visible in diary sidebar
  // - Daily view (default), show the current day
  // - Week view (todo), show a list of this week
  // - Calendar view (todo)
  visibleUserListIds: [],
};

export const useTaskListStore = create<ListStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initState,

        reset: () => {
          console.warn('Attention: reset');
          set(initState);
        },

        /// Derived state
        /// reference: https://github.com/pmndrs/zustand/issues/132#issuecomment-1120467721
        /// Unfortunitely, computed does not gets re-computed when defaultEisenhowerListMap is changed. I think best approach is to
        /// write separate function

        // computed: {
        //   get lists() {
        //     return { ...defaultEisenhowerListMap, ...( get()?.userListMap || {}) };
        //   },

        //   get listIds() {
        //     return Object.keys(get()?.itemsMap || {});
        //   },
        // },

        addUserList: (label: string, key: KV): uuid => {
          let newId = createUuid();
          const time = (new Date()).toISOString();
          set((state) => {
            // Check if the key already exists in the userListMap
            const keyExists = Object.values(state.userListMap).find(
              (list) => Object.keys(list?.keys || {}).some(k => list?.keys?.[k] === key?.[k])
            );

            // If the date does not exist, create a new list
            if (!keyExists) {

              // const [mor, aft, eve] = [createUuid(), createUuid(), createUuid()];

              return {
                ...state,
                userListMap: {
                  ...state.userListMap,
                  [newId]: {
                    id: newId,
                    keys: key,
                    label: label,
                    icon: '😶',
                    iconMutable: true,
                    createdDate: time,
                    updatedDate: time,
                  }
                },
                itemsMap: {
                  ...state.itemsMap,
                  [newId]: [
                    // TODO: add three disabled items: morning, afternoon, evening

                  ],
                },
              };
            } else {
              newId = keyExists.id;
              return state;
            }
          });
          return newId;
        },

        updateVisibleUserListIds: (listIds) => set({ visibleUserListIds: listIds }),

        setItemsMap: (listItemsMap) => set({ itemsMap: listItemsMap }),
        updateItemsMap: (updater) =>
          set((state) => ({
            itemsMap: updater(state.itemsMap),
          })),

        removeUserList: (id) => { },
        // set((state) => {
        //   const { [id]: _, ...restUserListMap } = state.userListMap;
        //   consoleLog('restUserListMap', restUserListMap);
        //   return { userListMap: restUserListMap, userListIds: Object.keys(restUserListMap) };
        // }),

        removeItemFromItemsMap: (containerId: uuid, itemId: uuid) => {
          set((state) => {
            // Find the container in itemsMap, and filter out the itemId
            const updatedItemsMap = { ...state.itemsMap };
            const container = updatedItemsMap[containerId];
            if (container) {
              updatedItemsMap[containerId] = container.filter((id) => id !== itemId);
            }
            return { itemsMap: updatedItemsMap };
          });
        },

        updateIcon: (id, icon) =>
          set((state) => {
            const updatedLists = { ...state.userListMap };
            if (updatedLists[id]) {
              updatedLists[id] = { ...updatedLists[id], icon };
            }
            return { userListMap: updatedLists };
          }),

        updateListDate: (id, updatedDate, overwriteCreatedDate) =>
          set((state) => {
            const updatedLists = { ...state.userListMap };
            const targetList = updatedLists[id];
            if (targetList) {
              updatedLists[id] = {
                ...targetList,
                updatedDate: updatedDate.toISOString(),
                createdDate:
                  overwriteCreatedDate?.toISOString() || targetList.createdDate,
              };
            }
            return { userListMap: updatedLists };
          }),
      }),
      {
        name: 'task-list-storage',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
