import React from 'react';
import { useCellValue, usePublisher } from '@mdxeditor/gurx';
import {
  ButtonWithTooltip,
  iconComponentFor$,
  useTranslation,
} from '@mdxeditor/editor';
import { openLinkEditDialog$ } from '../LinkDialogAdvanced/linkDialogStateAdvanced';

/**
 * A toolbar component that opens the link edit dialog.
 * For this component to work, you must include the `linkDialogPlugin`.
 * @group Toolbar Components
 */
export const CreateLink = () => {
  const openLinkDialog = usePublisher(openLinkEditDialog$);
  const iconComponentFor = useCellValue(iconComponentFor$);
  const t = useTranslation();
  return (
    <ButtonWithTooltip
      aria-label={t('toolbar.link', 'Create link')}
      title={t('toolbar.link', 'Create link')}
      onClick={(_) => {
        openLinkDialog();
      }}
    >
      {iconComponentFor('link')}
    </ButtonWithTooltip>
  );
};
