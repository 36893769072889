import { Button, Flex, IconButton, Separator, Text } from "@radix-ui/themes";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./UserListCalendar.module.scss"
import { consoleLog } from "../../utilities/console";

import { TriangleLeftIcon, TriangleRightIcon, ResetIcon } from '@radix-ui/react-icons'
import useUserConfigStore from "../../stores/userConfigStore";
import { addDays, format, subDays } from "date-fns";
import classNames from "classnames";
import { DAY_, useTaskListStore } from "../../stores/useTaskListStore";

const UserListCalendar = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const selectedDay = format(selectedDate, "yyyy'-'MM'-'dd'");

  const [selectedWeek, setSelectedWeek] = useState<number | null>(null);
  // const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const serverTime = useUserConfigStore((s) => s.serverTime);

  const isTodaySelected = selectedDay === format(serverTime, "yyyy'-'MM'-'dd");
  const addUserList = useTaskListStore(s => s.addUserList)
  const updateVisibleUserListIds = useTaskListStore(s => s.updateVisibleUserListIds)

  useEffect(() => {
    const dayId = addUserList(selectedDay, { day: selectedDay });
    updateVisibleUserListIds([dayId]);
  }, [selectedDate]);

  return (
    <Flex align="center">
      <IconButton variant="soft" onClick={() => {
        setSelectedDate(prev => subDays(prev, 1))
      }}>
        <TriangleLeftIcon width={16} height={16} />
      </IconButton>
      <Separator orientation="vertical" />
      <DatePicker
        // open={isCalendarOpen}
        // onCalendarOpen={() => setIsCalendarOpen(true)}
        // onCalendarClose={() => setIsCalendarOpen(false)}
        selected={selectedDate}
        onChange={(date: Date | null) => date && setSelectedDate(date)}
        customInput={
          <Button className={classNames(styles.dataPickerButton, !isTodaySelected && styles.notToday)} variant="soft" size="2" asChild >
            <Text truncate={true} highContrast={true}>{format(selectedDate, "yyyy'-'MM'-'dd")}</Text>
          </Button>
        }
      />
      {!isTodaySelected ? <Button variant="soft" size="2" onClick={() => {
        setSelectedDate(serverTime)
      }}>
        <ResetIcon width={16} height={16} />
        {/* <Text>Today</Text> */}
      </Button> : null}
      <Separator orientation="vertical" />
      <IconButton variant="soft" onClick={() => {
        setSelectedDate(prev => addDays(prev, 1))
      }}>
        <TriangleRightIcon width={16} height={16} />
      </IconButton>
    </Flex>
  );
};

export default UserListCalendar;
