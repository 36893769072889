import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks';

import styles from './TaskItemViewer.module.css';
import classNames from 'classnames';
import { useTaskItemStore } from '../../stores/useTaskItemStore';
import { uuid } from '../../types/types';
import { useEffect, useRef, useState } from 'react';
import { Flex } from '@radix-ui/themes';

type Props = {
  itemId: uuid;
  value: string;
  placeholder?: string;
  setActive?: () => void;
};

export const TaskItemViewer = ({
  itemId,
  value,
  placeholder,
  setActive,
}: Props) => {
  const isPlaceholder = !value || value === placeholder;
  const nextActiveItemId = useTaskItemStore((s) => s.nextActiveItemId);

  /// when next active item is provided, set the current item to active. usually it's waiting for the new item to be initialized
  useEffect(() => {
    if (nextActiveItemId === itemId) {
      setActive?.();
    }
  }, [nextActiveItemId]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [showFadeOverlay, setShowFadeOverlay] = useState(false);

  // check if the content is overflowing and show the fade overlay if it is
  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const { clientHeight } = containerRef.current;
        // Need to get the element of class "prose"
        const proseElement = containerRef.current.querySelector('.prose');
        // Find all the children of the prose element
        const children = proseElement?.children || [];
        // And calculate the height of the children
        const childrenHeight = Array.from(children).reduce((acc, child) => {
          return acc + child.clientHeight;
        }, 0);

        setShowFadeOverlay(childrenHeight > clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [value]);

  return (
    <Flex className={styles.container} ref={containerRef}>

      <Markdown
        className={classNames(styles.viewerProse, 'prose', {
          [styles.placeholder]: isPlaceholder,
          [styles.fadeOverlay]: showFadeOverlay,
        })}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeRaw] as any}
        components={{
          /// Link always open in external window
          a: ({ node, ...props }) => (
            <a target="_blank" rel="noopener noreferrer" {...props} />
          ),
        }}
      >
        {value}
      </Markdown>
    </Flex>
  );
};
