import {
  useCellValues,
  editorRootElementRef$,
  activeEditor$,
  iconComponentFor$,
  usePublisher,
  onWindowChange$,
  updateLink$,
  cancelLinkEdit$,
  switchFromPreviewToLinkEdit$,
  removeLink$,
  useTranslation,
  IconKey,
  readOnly$,
  inFocus$,
} from '@mdxeditor/editor';
import classNames from 'classnames';
import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import * as Popover from '@radix-ui/react-popover';
import * as Tooltip from '@radix-ui/react-tooltip';

import styles from './LinkDialogAdvanced.module.scss';
import { linkDialogState$ } from './linkDialogStateAdvanced';

interface LinkEditFormProps {
  url: string;
  title: string;
  onSubmit: (link: { url: string; title: string }) => void;
  onCancel: () => void;
  onRemove: () => void;
  // linkAutocompleteSuggestions?: string[]
  iconComponentFor: (name: IconKey) => ReactNode;
}

interface LinkFormFields {
  url: string;
  title: string;
}

export function LinkEditForm({
  url,
  title,
  onSubmit,
  onCancel,
  onRemove,
  iconComponentFor,
}: LinkEditFormProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset: _,
  } = useForm<LinkFormFields>({
    values: {
      url,
      title,
    },
  });
  const t = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        console.debug('LinkDialog onSubmit');
        void handleSubmit(onSubmit)(e);
        e.stopPropagation();
        e.preventDefault();
      }}
      onReset={(e) => {
        e.stopPropagation();
        onCancel();
      }}
      className={classNames(styles.multiFieldForm, styles.linkDialogEditForm)}
    >
      <button
        className={styles.closeButton}
        type="reset"
        aria-label={t('createLink.cancelTooltip', 'Cancel change')}
        title={t('createLink.cancelTooltip', 'Cancel change')}
      >
        {iconComponentFor('close')}
      </button>

      <div className={styles.formField}>
        <label htmlFor="link-url">{t('createLink.url', 'URL')}</label>
        <input
          id="link-url"
          className={styles.textInput}
          size={30}
          autoFocus
          {...register('url')}
        />
      </div>

      {/* <DownshiftAutoComplete
            register={register}
            initialInputValue={url}
            inputName="url"
            suggestions={linkAutocompleteSuggestions}
            setValue={setValue}
            control={control}
            placeholder={t('createLink.urlPlaceholder', 'Select or paste an URL')}
            autofocus
          /> */}

      {/* <div className={styles.formField}>
        <label htmlFor="link-title">{t('createLink.title', 'Title')}</label>
        <input id="link-title" className={styles.textInput} size={40} {...register('title')} />
      </div> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          gap: 'var(--spacing-2)',
        }}
      >
        <button
          type="submit"
          title={t('createLink.saveTooltip', 'Set URL')}
          aria-label={t('createLink.saveTooltip', 'Set URL')}
          className={classNames(styles.primaryButton)}
        >
          {/* {t('dialogControls.save', 'Save')} */}
          {iconComponentFor('check')}
        </button>
        {/* <button
          type="reset"
          title={t('createLink.cancelTooltip', 'Cancel change')}
          aria-label={t('createLink.cancelTooltip', 'Cancel change')}
          className={classNames(styles.secondaryButton)}
        >
          {t('dialogControls.cancel', 'Cancel')}
        </button> */}

        <button
          title={t('linkPreview.remove', 'Remove link')}
          aria-label={t('linkPreview.remove', 'Remove link')}
          className={classNames(styles.secondaryButton)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--spacing-1)',
          }}
          onClick={() => {
            onRemove();
          }}
        >
          {iconComponentFor('link_off')}
          {t('dialogControls.save', 'Remove link')}
        </button>
      </div>
    </form>
  );
}

export interface LinkDialogAdvancedRef {
  // show: () => void
  hide: () => void;
}

/// Change this to accept a ForwardRef which is a boolean to show or hide the dialog
export const LinkDialogAdvanced = forwardRef<LinkDialogAdvancedRef>(
  (props, ref) => {
    const [
      editorRootElementRef,
      activeEditor,
      iconComponentFor,
      linkDialogState,
    ] = useCellValues(
      editorRootElementRef$,
      activeEditor$,
      iconComponentFor$,
      // linkDialogState$,
      linkDialogState$
      // linkAutocompleteSuggestions$,
      // onClickLinkCallback$
    );
    const publishWindowChange = usePublisher(onWindowChange$);
    const updateLink = usePublisher(updateLink$);
    const cancelLinkEdit = usePublisher(cancelLinkEdit$);
    const switchFromPreviewToLinkEdit = usePublisher(
      switchFromPreviewToLinkEdit$
    );
    const removeLink = usePublisher(removeLink$);
    const publishLinkDialogState = usePublisher(linkDialogState$);

    /// publish readOnly$ to true actually does not resolve the issue
    /// foundamental issue is that the link dialog gets triggered when the current selection is a link

    // const publishReadOnly = usePublisher(readOnly$)
    // const publishInFocus = usePublisher(inFocus$)

    useImperativeHandle(ref, () => ({
      // show: () => publishLinkDialogState(true),
      hide: () => {
        // consoleLog('hiding link dialog in LinkDialogAdvanced');
        publishLinkDialogState({ type: 'inactive' });
      },
    }));

    React.useEffect(() => {
      const update = () => {
        activeEditor?.getEditorState().read(() => {
          publishWindowChange(true);
          // consoleLog('active', editorRootElementRef, activeEditor)
        });
      };

      window.addEventListener('resize', update);
      window.addEventListener('scroll', update);

      return () => {
        window.removeEventListener('resize', update);
        window.removeEventListener('scroll', update);
      };
    }, [activeEditor, publishWindowChange]);

    const [copyUrlTooltipOpen, setCopyUrlTooltipOpen] = React.useState(false);

    const t = useTranslation();

    const theRect = linkDialogState.rectangle;

    const urlIsExternal =
      linkDialogState.type === 'preview' &&
      linkDialogState.url.startsWith('http');
    const isOpen = linkDialogState.type !== 'inactive';

    return (
      <Popover.Root open={isOpen}>
        <Popover.Anchor
          data-visible={linkDialogState.type === 'edit'}
          className={styles.linkDialogAnchor}
          style={{
            top: `${theRect?.top ?? 0}px`,
            left: `${theRect?.left ?? 0}px`,
            width: `${theRect?.width ?? 0}px`,
            height: `${theRect?.height ?? 0}px`,
          }}
        />

        <Popover.Portal container={editorRootElementRef?.current}>
          <Popover.Content
            className={classNames(styles.linkDialogPopoverContent)}
            sideOffset={5}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            key={linkDialogState.linkNodeKey}
          >
            {linkDialogState.type === 'edit' && (
              <LinkEditForm
                url={linkDialogState.url}
                title={linkDialogState.title}
                onSubmit={updateLink}
                onCancel={cancelLinkEdit.bind(null)}
                onRemove={removeLink.bind(null)}
                iconComponentFor={iconComponentFor}
              // linkAutocompleteSuggestions={linkAutocompleteSuggestions}
              />
            )}

            {linkDialogState.type === 'preview' && (
              <>
                <a
                  className={styles.linkDialogPreviewAnchor}
                  href={linkDialogState.url}
                  {...(urlIsExternal
                    ? { target: '_blank', rel: 'noreferrer' }
                    : {})}
                  //   onClick={(e) => {
                  //     if (onClickLinkCallback !== null) {
                  //       e.preventDefault()
                  //       onClickLinkCallback(linkDialogState.url)
                  //     }
                  //   }}
                  title={
                    urlIsExternal
                      ? t('linkPreview.open', `Open {{url}} in new window`, {
                        url: linkDialogState.url,
                      })
                      : linkDialogState.url
                  }
                >
                  {urlIsExternal && iconComponentFor('open_in_new')}
                  <span>{linkDialogState.url}</span>
                </a>

                <Tooltip.Provider>
                  <Tooltip.Root open={copyUrlTooltipOpen}>
                    <Tooltip.Trigger asChild>
                      <ActionButton
                        title={t(
                          'linkPreview.copyToClipboard',
                          'Copy to clipboard'
                        )}
                        aria-label={t(
                          'linkPreview.copyToClipboard',
                          'Copy to clipboard'
                        )}
                        onClick={() => {
                          try {
                            // navigator.clipboard is not supported in localhost
                            void window.navigator.clipboard
                              .writeText(linkDialogState.url)
                              .then(() => {
                                setCopyUrlTooltipOpen(true);
                                setTimeout(() => {
                                  setCopyUrlTooltipOpen(false);
                                }, 1000);
                              });
                          } catch (e) {
                            console.error('Error copying to clipboard', e);
                          }
                        }}
                      >
                        {copyUrlTooltipOpen
                          ? iconComponentFor('check')
                          : iconComponentFor('content_copy')}
                      </ActionButton>
                    </Tooltip.Trigger>
                    <Tooltip.Portal container={editorRootElementRef?.current}>
                      <Tooltip.Content
                        className={classNames(styles.tooltipContent)}
                        sideOffset={5}
                      >
                        {t('linkPreview.copied', 'Copied!')}
                        <Tooltip.Arrow />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>

                <ActionButton
                  onClick={() => {
                    switchFromPreviewToLinkEdit();
                  }}
                  title={t('linkPreview.edit', 'Edit link URL')}
                  aria-label={t('linkPreview.edit', 'Edit link URL')}
                >
                  {iconComponentFor('edit')}
                </ActionButton>
              </>
            )}
            <Popover.Arrow className={styles.popoverArrow} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
);

const ActionButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'>
>(({ className, ...props }, ref) => {
  return (
    <button
      className={classNames(styles.actionButton, className)}
      ref={ref}
      {...props}
    />
  );
});
