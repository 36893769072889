import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  useDroppable,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
  closestCorners,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates.ts';

import { Item, Container, ContainerProps } from '../../components/index.ts';

import { createRange, createUuid } from '../../utilities/index.ts';
import { Arguments } from '@dnd-kit/sortable/dist/hooks/useSortable';
import { Collision } from '@dnd-kit/core/dist/utilities/algorithms/types';

import { Pencil2Icon, RocketIcon, CheckCircledIcon } from '@radix-ui/react-icons'

// import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor.tsx';

// import type {Active, RectMap} from '@dnd-kit/core/dist/store';
// import type {ClientRect, Coordinates} from '@dnd-kit/core/dist/types';

import styles from './MultipleContainers.module.css';
// import { TaskItemCategory } from '../../types/types.ts';
import { useTaskItemStore } from '../../stores/useTaskItemStore.ts';
import {
  DAY_,
  List,
  ItemsMap,
  useTaskListStore,
  getListIds,
  PRIORITY,
  defaultEisenhowerListMap,
  // DAY_ZERO_LIST_ID,
} from '../../stores/useTaskListStore.ts';
import { uuid } from '../../types/types.ts';
import { LoremIpsum } from 'lorem-ipsum';

// import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor.tsx';
// import { TaskItemViewer } from '../TaskItemViewer/TaskItemViewer.tsx';
import { TaskItem } from '../TaskItem/TaskItem.tsx';
import { Badge, Box, Button, DropdownMenu, Flex, IconButton, Text } from '@radix-ui/themes';
import * as cn from 'classnames';
import UserListCalendar from '../UserListCalendar/UserListCalendar';
import { format } from 'date-fns';
import { useDebounce } from '@uidotdev/usehooks';
import { consoleLog } from '../../utilities/console.ts';

// const getDayLabel = (list: List): React.ReactNode => {
//   const now = new Date();
//   // All list that can not find `DAY_` prefix in all keys 
//   if (list.keys?.findIndex((v) => v.startsWith(DAY_)) == -1) {
//     return list.label || '';
//   } else {
//     // Day list
//     const createdDate = list.createdDate ? new Date(list.createdDate) : new Date();
//     const dateString = format(createdDate, 'MM/dd/yyyy');
//     // TODO: add a ' < Today > ' buttongroup.
//     // if (now.isSame(createdDate, 'day')) {
//     //   return 'Today' + ' - ' + dateString;
//     // } else if (now.subtract(1, 'day').isSame(createdDate, 'day')) {
//     //   return 'Yesterday' + ' - ' + dateString;
//     // } else if (now.add(1, 'day').isSame(createdDate, 'day')) {
//     //   return 'Tomorrow' + ' - ' + dateString;
//     // }
//     return dateString;
//   }
// };

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

/*show add new item button when there is already one task in the container*/
function createContainerFooter(onAddNewItemClick: (options?: Record<string, string | boolean>) => void) {
  return (
    <div className={styles.addNewItemContainer}>
      <Button
        variant={'soft'}
        onClick={() => onAddNewItemClick()}
        className={styles.addNewItemButton}
      >
        <Pencil2Icon />
        <span className={styles.text}>New Task</span>
      </Button>

      <div className={styles.addNewItemDropdown}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="soft" className={styles.addNewItemDropdownTrigger}>
              <DropdownMenu.TriggerIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <DropdownMenu.Item
              // shortcut="⌘ E"
              onClick={() => onAddNewItemClick({ type: 'NEW_DONE' })}
            >
              {/* <RocketIcon /> */}
              <CheckCircledIcon />
              <span className={styles.text}>Add Completed</span>
            </DropdownMenu.Item>

          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>

    </ div>
  )
}

function DroppableContainer(
  droppableContainerProps: ContainerProps & {
    disabled?: boolean;
    showDroppable?: boolean;
    id: uuid;
    items: uuid[];
    style?: React.CSSProperties;
    onAddNewItemClick?: (options?: Record<string, string | boolean>) => void;
  }
) {
  const {
    children,
    columns = 1,
    disabled,
    showDroppable,
    id,
    items,
    style,
    onAddNewItemClick,
    ...props
  } = droppableContainerProps;

  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  } as Arguments);
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
    items.includes(over.id?.toString())
    : false;

  const footer = null; // createContainerFooter(onAddNewItemClick)

  return (
    <Container
      id={id}
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      // showActions={true}
      showDroppable={showDroppable}
      // onClick={onClearActiveItem}
      onAddNewItemClick={onAddNewItemClick}
      columns={columns}
      footer={footer}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;

  getItemStyles?(args: {
    value: uuid;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: uuid;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;

  wrapperStyle?(args: { index: number }): React.CSSProperties;

  // itemCount?: number;
  // items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
}

export const TRASH_ID = 'void';
/// PLACEHOLDER_ID is used for adding new items to the list, not used now
// const PLACEHOLDER_ID = 'placeholder';
// const empty: UniqueIdentifier[] = [];

export function MultipleContainers(props: Props) {
  const {
    adjustScale = false,
    cancelDrop,
    columns,
    handle = false,
    // itemCount = 3,
    // items: initialItems,
    containerStyle,
    // coordinateGetter = multipleContainersCoordinateGetter,
    getItemStyles = () => ({}),
    wrapperStyle = () => ({}),
    minimal = false,
    modifiers,
    renderItem,
    strategy = verticalListSortingStrategy,
    trashable = false,
    vertical = false,
    scrollable,
  } = props;

  // const [items, setItems] = useState<Items>(
  //   () =>
  //     initialItems ?? {
  //       PRIORITY_A: [],
  //       PRIORITY_B: createRange(itemCount, (index) => `B${index + 1}`),
  //       PRIORITY_C: createRange(itemCount, (index) => `C${index + 1}`),
  //       PRIORITY_D: createRange(itemCount, (index) => `D${index + 1}`),
  //       TODAY: [],
  //     },
  // );

  // Items is a dictionary, key as listId, value as an array of related task item ids.
  const items = useTaskListStore((s) => s.itemsMap);
  // user list keys are muted, task list is immutable (could be updated by configuration only)
  const userListMap = useTaskListStore((s) => s.userListMap);
  const taskListMap = useTaskListStore((s) => s.taskListMap);
  const visibleUserListIds = useTaskListStore((s) => s.visibleUserListIds);
  // One container is a viewer instance of one List
  const userListContainers = getListIds(userListMap);
  const taskListContainers = getListIds(taskListMap);
  const listMap = { ...userListMap, ...taskListMap };
  const containers = getListIds(listMap);

  const setItems = useTaskListStore((s) => s.setItemsMap);
  const updateItems = useTaskListStore((s) => s.updateItemsMap);
  const updateListDate = useTaskListStore((s) => s.updateListDate);
  const removeItemFromItemsMap = useTaskListStore((s) => s.removeItemFromItemsMap);

  // Current active item id, this is the local state that managed by Item Store
  const activeItemId = useTaskItemStore((s) => s.activeItemId);
  const setActiveItemId = useTaskItemStore((s) => s.setActiveItemId);
  const setNextActiveItemId = useTaskItemStore((s) => s.setNextActiveItemId);
  const updateItemContent = useTaskItemStore((s) => s.updateContent);
  const enableGlobalReadOnly = useTaskItemStore((s) => s.enableGlobalReadOnly);
  const disableGlobalReadOnly = useTaskItemStore((s) => s.disableGlobalReadOnly);

  // When the droppable container is explicitly clicked, set the active item to none
  // const handleClickToClearActiveItem = (event: React.MouseEvent<HTMLElement>) => {
  //   // Stop propagation if the target is not the container itself
  //   if (event.target !== event.currentTarget) {
  //     event.stopPropagation();
  //     return;
  //   }
  //   setActiveItemId(null);
  // };

  /// TODO: we don't allow dragging/adding/removing containers, so we don't need this
  // const [containers, setContainers] = useState(
  //   Object.keys(items) as uuid[],
  // );
  // const containers = Object.keys(items) as uuid[];
  // consoleLog('containers', containers);

  // Current active item id, this is the local state that managed by DndContext
  // In original offcial MultipleContainers, container and item are both movable, so activeId could be the 
  // id of the moving container: which is a big issue that needs to consider when we refactor. 
  const [activeId, setActiveId] = useState<uuid | null>(null);
  const lastOverId = useRef<uuid | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  // const isSortingContainer = activeId ? containers.includes(activeId) : false;

  // Only one container is being sorted at a time. The motivation is to fix when two vertical virtual containers
  // overlap, dragging an item will cause indefinite loop
  const [sortingContainerId, setSortingContainerId] = useState<uuid | null>(null);
  // This container will highlight with an showDroppable indicator
  const [showDroppableContainerId, setShowDroppableContainerId] = useState<uuid | null>(null);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */

  /**
   * Custom collision optimized for done lit
   * 
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin({
        ...args,
        droppableContainers: args.droppableContainers
          .filter(
            (container) => container.id in items
          ),
      });

      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
          pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      // consoleLog(`overid`, overId, overId && overId in items ? `[is container]` : '');

      const getDrops = (args: any) => {
        // it's a container
        if (overId && overId in items) {
          // find items inside, so sorting inside the container is allowed
          if (overId == sortingContainerId) {
            return args.droppableContainers.filter(
              (container: any) => items[overId].includes(container.id)
            )
          }
          // moving to other containers, return the whole container. 
          else {
            return [{ id: overId }] as Collision[];
          }
        }

        return args.droppableContainers
      }

      const itemIntersection = closestCenter({
        ...args,
        droppableContainers: getDrops(args)
      });
      return itemIntersection;

      /// TODO: study if lastOverId makes sense
      /*
      lastOverId.current = overId?.toString() || null;
      
      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current
        ? ([{ id: lastOverId.current }] as Collision[])
        : [];
      */
    },
    [activeId, items, sortingContainerId]
  );


  /**
   * Cloned items are used to revert to the state if the drag is cancelled.
   */
  const [clonedItems, setClonedItems] = useState<ItemsMap | null>(null);

  // const activationConstraint = {
  //   delay: 100,
  //   tolerance: 5,
  // };

  const sensors = useSensors(
    useSensor(MouseSensor,
      // {
      //   activationConstraint,
      // }
    ),
    useSensor(TouchSensor,
      // {
      //   activationConstraint,
      // }
    ),
    // By passing empty keyboardCodes, we don't want keyboard to active the item
    useSensor(KeyboardSensor, {
      // coordinateGetter,
      keyboardCodes: {
        start: [],
        cancel: [],
        end: [],
      },
    })
  );
  const findContainer = (id: uuid) => {
    if (id in items) {
      return id.toString();
    }

    return Object.keys(items)
      .find((key) => items[key].includes(id.toString()))
      ?.toString();
  };

  const getIndex = (id: uuid) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id.toString());

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  // If Day Zero never been updated, it means that the user is using the app for the first time
  // We need to initialize the list with key `DEFAULT_DAY_ZERO` with the current date for both created and updated dates
  // useEffect(() => {
  //   if (items[DAY_ZERO_LIST_ID]?.length === 0) {
  //     const now = new Date();
  //     updateListDate(DAY_ZERO_LIST_ID, now, now);
  //   }
  // }, []);
  const getPriority = (containerId: uuid) => {
    const list = listMap?.[containerId];
    return list?.keys?.priority || '';
  }

  const renderDroppableContainer = (containerId: uuid) => {
    const list = listMap?.[containerId];
    const showDroppable = showDroppableContainerId === containerId && sortingContainerId !== containerId;
    return (
      <div
        className={styles.droppableContainer}
        key={`dropContainer-${containerId}`}
      >
        <DroppableContainer
          key={containerId}
          id={containerId}
          label={minimal ? undefined : list?.label || ''}
          icon={list.icon || ''}
          showActions={true}
          iconMutable={list?.iconMutable || false}
          //todo-pudu: when there are more than 2 items, here is the best place to change columns to 2
          columns={columns}
          items={items[containerId]}
          scrollable={scrollable}
          // todo revert to params
          style={containerStyle}
          showDroppable={showDroppable}
          unstyled={minimal}
          // onRemove={() => handleRemove(containerId)}
          onAddNewItemClick={(options?: any) => handleAddNewItem(containerId, options)}
        >
          <SortableContext items={items[containerId] || []} strategy={strategy} id={containerId}>
            {items[containerId] ? (
              items[containerId].map((value, index) => {
                const isItemDisabled = sortingContainerId !== null && sortingContainerId !== containerId;
                return (
                  <SortableItem
                    disabled={isItemDisabled}
                    key={value}
                    id={value}
                    index={index}
                    // Only when the item is editor active
                    // we respect the handle props, otherwise we don't allow dragging

                    // TODO: even totally hide drag handle.
                    // TODO: add minimun drag distance, so click to edit is easier
                    // TODO: rename activeItemId to editorActiveItemId

                    // TODO: open a new door for floating menu, to keep the focus state?
                    // handle={activeItemId === value ? true : handle}
                    handle={handle}
                    style={getItemStyles}
                    wrapperStyle={wrapperStyle}
                    renderItem={renderItem}
                    onRemove={() => {
                      // consoleLog('onRemove', value);
                      removeItemFromItemsMap(containerId, value)
                    }}
                    containerId={containerId}
                    useDragOverlay={false}
                    getIndex={getIndex}
                  />
                );
              })
            ) : (
              <div>No items</div>
            )}
          </SortableContext>
        </DroppableContainer>
      </div >
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {

        consoleLog('[onDragStart]', active.id, active.data);

        // set the current sorting container
        const sortingContainerId = active.data?.current?.sortable?.containerId
        setSortingContainerId(sortingContainerId)
        setShowDroppableContainerId(sortingContainerId)

        // Immediately switch all items to view mode when dragging starts
        enableGlobalReadOnly()
        setActiveItemId(null)

        // local state update
        setActiveId(active.id?.toString());
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        consoleLog('[onDragOver]', ' active: ', active.id, ' over :', overId,);

        if (overId == undefined) {
          debugger
        }

        // To keep a last 10 logs of overId, if they are A - B - A - B infinite loop
        // stopping updating the list, cancel the animation

        if (overId == null || overId == undefined || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId?.toString());
        const activeContainer = findContainer(active.id?.toString());

        if (!overContainer || !activeContainer) {
          return;
        }

        /// This is the final solution after 5 days of research.
        /// The orignal `updateItems` is very problematic, it will cause the whole app to crash
        /// as updating the items will cause indefinite loop.
        setShowDroppableContainerId(overContainer);

        /*
        /// Original logic
        if (activeContainer !== overContainer) {
          updateItems((items: ItemsMap) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId.toString());
            const activeIndex = activeItems.indexOf(active.id.toString());
  
            let newIndex: number;
  
            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                over.rect.top + over.rect.height;
  
              const modifier = isBelowOverItem ? 1 : 0;
  
              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }
  
            recentlyMovedToNewContainer.current = true;
  
            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            } as ItemsMap;
          });
        }
        */
      }
      }
      onDragEnd={({ active, over, delta }) => {
        // To immediately switch to view mode when dragging starts
        disableGlobalReadOnly()

        // over.id is null when the user clicks on the item and did not move it at all
        // it's expected to edit the item
        if (over?.id == null && active.id !== null) {
          setActiveItemId(active.id.toString());
        }

        /// This branch is executed when the user drags an Container, not an Item
        /// For now we don't allow dragging containers
        // if (active.id in items && over?.id) {
        //   setContainers((containers) => {
        //     const activeIndex = containers.indexOf(active.id?.toString());
        //     const overIndex = containers.indexOf(over.id?.toString());

        //     return arrayMove(containers, activeIndex, overIndex);
        //   });
        // }

        const activeContainer = findContainer(active.id?.toString()) as uuid;

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        /// This branch is not needed, as we don't allow trash container
        // if (overId === TRASH_ID) {
        //   updateItems((items) => ({
        //     ...items,
        //     [activeContainer]: items[activeContainer].filter(
        //       (id) => id !== activeId
        //     ),
        //   }));
        //   setActiveId(null);
        //   return;
        // }

        /// This branch is executed when the user drags an item to the placeholder column
        // if (overId === PLACEHOLDER_ID) {
        //   const newContainerId = getNextContainerId();
        //   unstable_batchedUpdates(() => {
        //     setContainers((containers) => [...containers, newContainerId]);
        //     updateItems((items) => ({
        //       ...items,
        //       [activeContainer]: items[activeContainer].filter(
        //         (id) => id !== activeId,
        //       ),
        //       [newContainerId]: [active.id?.toString()],
        //     }));
        //     setActiveId(null);
        //   });
        //   return;
        // }

        const overContainer = findContainer(overId?.toString());

        if (overContainer) {
          if (activeContainer === overContainer) {
            // consoleLog('sorting ends, container does not change')
            // sorting item in the same container
            const activeIndex = items[activeContainer].indexOf(
              active.id?.toString()
            );
            const overIndex = items[overContainer].indexOf(overId?.toString());

            if (activeIndex !== overIndex) {
              updateItems((items) => ({
                ...items,
                [overContainer]: arrayMove(
                  items[overContainer],
                  activeIndex,
                  overIndex
                ),
              }));
            }
          } else {
            // now the item is dragged over a different container
            // no sorting, instead insert directly to its top
            // consoleLog('sorting ends, moving to the start/end of the other container')
            updateItems((items) => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(id => id !== active?.id.toString()),
              [overContainer]:
                overContainer in defaultEisenhowerListMap ?
                  [active?.id.toString(), ...items[overContainer]] : [...items[overContainer], active?.id.toString()]
            }));

            const newPriority = getPriority(overContainer)
            // and update the keys, change `priority` to current level
            updateItemContent(active?.id.toString(), undefined, undefined, undefined, newPriority ? { priority: newPriority } : {});
          }
        }

        // reset current sorting container
        setSortingContainerId(null)
        setShowDroppableContainerId(null)

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div
        className={styles.container}>
        {/*<SortableContext*/}
        {/*  items={[...containers, PLACEHOLDER_ID]}*/}
        {/*  strategy={*/}
        {/*    vertical*/}
        {/*      ? verticalListSortingStrategy*/}
        {/*      : horizontalListSortingStrategy*/}
        {/*  }*/}
        {/*>*/}

        <div className={styles.taskList}>
          {taskListContainers.map(containerId => renderDroppableContainer(containerId))}
        </div>

        <div className={styles.userList}>
          <Flex align="center" justify="between" px="3">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="soft">
                  <Text highContrast={true}>📔{' '}Diary</Text>
                  <DropdownMenu.TriggerIcon />
                </Button>
              </DropdownMenu.Trigger>
              {/* <DropdownMenu.Content>
                <DropdownMenu.Item shortcut="⌘ E">Edit</DropdownMenu.Item>
                <DropdownMenu.Item shortcut="⌘ D">Duplicate</DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item shortcut="⌘ N">Archive</DropdownMenu.Item>

                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>More</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.Item>Move to project…</DropdownMenu.Item>
                    <DropdownMenu.Item>Move to folder…</DropdownMenu.Item>

                    <DropdownMenu.Separator />
                    <DropdownMenu.Item>Advanced options…</DropdownMenu.Item>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>

                <DropdownMenu.Separator />
                <DropdownMenu.Item>Share</DropdownMenu.Item>
                <DropdownMenu.Item>Add to favorites</DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item shortcut="⌘ ⌫" color="red">
                  Delete
                </DropdownMenu.Item>
              </DropdownMenu.Content> */}
            </DropdownMenu.Root>
            <UserListCalendar />
          </Flex>
          <ul className={styles.taskListContainer}>
            {/* {userListContainers.map(containId => renderDroppableContainer(containId))} */}
            {visibleUserListIds.map(containerId => renderDroppableContainer(containerId))}
          </ul>
        </div>

        {/*{minimal ? undefined : (*/}
        {/*  <DroppableContainer*/}
        {/*    id={PLACEHOLDER_ID}*/}
        {/*    disabled={isSortingContainer}*/}
        {/*    items={empty}*/}
        {/*    onClick={handleAddColumn}*/}
        {/*    placeholder*/}
        {/*  >*/}
        {/*    + Add column*/}
        {/*  </DroppableContainer>*/}
        {/*)}*/}
        {/*</SortableContext>*/}
      </div>
      {
        createPortal(
          <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
            {activeId
              ? // containers.includes(activeId)
              // ? renderContainerDragOverlay(activeId) :
              renderSortableItemDragOverlay(activeId)
              : null}
          </DragOverlay>,
          document.body
        )
      }
      {/* {trashable && activeId && !containers.includes(activeId) ? (
        <Trash id={TRASH_ID} />
      ) : null} */}
    </DndContext >
  );

  /**
   * When the item is being dragged, we render a drag overlay
   * @param id
   */
  function renderSortableItemDragOverlay(id: uuid) {
    return (
      <Item
        value={<TaskItem id={id} defaultValue={'Moving to ...'} />}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as uuid,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  /**
   * When the container is being dragged, we render a drag overlay
   * @param containerId
   */
  // function renderContainerDragOverlay(containerId: uuid) {
  //   return (
  //     <Container
  //       id={containerId}
  //       label={`Column ${containerId} Drag Overlay`}
  //       columns={columns}
  //       style={{
  //         height: '100%',
  //       }}
  //       shadow
  //       unstyled={false}
  //     >
  //       {items[containerId].map((item, index) => (
  //         <Item
  //           key={item}
  //           value={item}
  //           handle={handle}
  //           style={getItemStyles({
  //             containerId,
  //             overIndex: -1,
  //             index: getIndex(item),
  //             value: item,
  //             isDragging: false,
  //             isSorting: false,
  //             isDragOverlay: false,
  //           })}
  //           color={getColor(item)}
  //           wrapperStyle={wrapperStyle({ index })}
  //           renderItem={renderItem}
  //         />
  //       ))}
  //     </Container>
  //   );
  // }

  // function handleRemove(containerID: uuid) {
  //   setContainers((containers) =>
  //     containers.filter((id) => id !== containerID),
  //   );
  // }

  function handleAddNewItem(
    containerID: uuid,
    options?: Record<string, string | boolean>
  ) {

    consoleLog('handleAddNewItem', containerID, options);
    // use containerID to find the container and add a new item
    const newItemId = createUuid();

    unstable_batchedUpdates(() => {
      // cretea a new item and append to the container
      updateItems((items) => ({
        ...items,
        [containerID]: [...items[containerID], newItemId],
      }));
      consoleLog('xxx', getPriority(containerID))
      updateItemContent(newItemId, undefined, undefined, undefined, { priority: getPriority(containerID) });
      // Reason: we want to make sure the new item gets active and focused so customer can start editing right away
      // However the new item might not get updated, so we use next active item id. 
      // setNextActiveItemId(newItemId);
      consoleLog('added a new item id', newItemId);
      if (process.env.IS_DEV) {
        const lorem = new LoremIpsum({
          sentencesPerParagraph: {
            max: 8,
            min: 4,
          },
          wordsPerSentence: {
            max: 16,
            min: 4,
          },
        });
        updateItemContent(newItemId, lorem.generateSentences(2));
      }
    });
  }

  // function handleAddColumn() {
  //   const newContainerId = getNextContainerId();

  //   unstable_batchedUpdates(() => {
  //     setContainers((containers) => [...containers, newContainerId]);
  //     updateItems((items) => ({
  //       ...items,
  //       [newContainerId]: [],
  //     }));
  //   });
  // }

  function getNextContainerId() {
    // const containerIds = Object.keys(items);
    // const lastContainerId = containerIds[containerIds.length - 1];

    // return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
    return createUuid();
  }
}

/// TODO: this color tag can be further used
export function getColor(priority: any) {
  switch (priority) {
    case PRIORITY.EM_IMP_URG:
      return '#a1c4fd';
    case PRIORITY.EM_URG:
      return '#fcb69f';
    case PRIORITY.EM_IMP:
      return '#c1dfc4';
    case PRIORITY.EM_LOW:
      return '#c4c5c7';
  }

  return undefined;
}

function Trash({ id }: { id: uuid }) {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        marginLeft: -150,
        bottom: 20,
        width: 300,
        height: 60,
        borderRadius: 5,
        border: '1px solid',
        borderColor: isOver ? 'red' : '#DDD',
      }}
    >
      Drop here to delete
    </div>
  );
}


interface SortableItemProps {
  containerId: uuid;
  id: uuid;
  index: number;
  handle: boolean;
  disabled?: boolean;
  useDragOverlay?: boolean;
  color?: string;

  style(args: any): React.CSSProperties;

  getIndex(id: uuid): number;

  renderItem(): React.ReactElement;
  onRemove?(): void;

  wrapperStyle({ index }: { index: number }): React.CSSProperties;
}

function SortableItem({
  disabled,
  useDragOverlay,
  id,
  index,
  handle,
  renderItem,
  onRemove,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  color,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    resizeObserverConfig: {},
    id,
    disabled,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  /// Todo: save the active item id in the store
  /// so only the active item is rendered using the Editor and all other items are rendered using the Viewer
  // const emptyContent = 'Type to create a new [item](https://www.google.com)';
  const emptyContent = 'Jot something down';
  const value = useMemo(() => <TaskItem id={id} defaultValue={emptyContent} />, [id, emptyContent]);

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={value}
      // value={<><p>{id}</p>{containerId}</>}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(`${over.id}`) : overIndex,
        containerId,
      })}
      color={color}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      onRemove={onRemove}
      dragOverlay={useDragOverlay}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
