import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { uuid } from '../types/types.ts';
import { createUuid } from '../utilities';
import { devtools } from 'zustand/middleware';
import type { } from '@redux-devtools/extension';
import type { KV } from '../types/types';


type ItemState = {
  id: uuid;
  title: string; // use the first line (and limit to 40 characters) of the content as the title
  content: string; // markdown string
  listId: uuid; // each item must belong to a list
  keys?: KV; // an maps of strings, internal usage

  createdDate: Date;
  updatedDate?: Date;
};

type ItemStore = {
  items: Record<uuid, ItemState>;
  // only active item is rendered using the Editor and all other items are rendered using the Viewer
  activeItemId: uuid | null;
  // nextActiveItemId is used to record the next active item id, often the item is not yet created
  // this variable should be null most of the time. 
  nextActiveItemId: uuid | null;

  // Each item has its own editing state, which is independent from others
  // isGlobalReadOnly is true when the global read only mode is on: e.g. when dragging starts, we set all items to read only
  // when dragging ends, we set it to false. Dndkit would easily crash when editable content is present. 
  isGlobalReadOnly: boolean;
};

type ItemStoreAction = {
  updateContent: (
    id: uuid,
    content?: string,
    title?: string,
    listId?: uuid,
    keys?: KV,
  ) => void;

  setActiveItemId: (id: uuid | null) => void;
  setNextActiveItemId: (id: uuid) => void;

  enableGlobalReadOnly: () => void;
  disableGlobalReadOnly: () => void;
};

// Create a store of the type State & Actions
export const useTaskItemStore = create<ItemStore & ItemStoreAction>()(
  devtools(
    persist(
      (set, get) => ({
        items: {},
        activeItemId: null,
        nextActiveItemId: null,
        isGlobalReadOnly: false,

        updateContent: (
          id,
          content,
          title,
          listId,
          keys,
        ) => {
          set((state) => {
            const item = state.items[id];
            return {
              items: {
                ...state.items,
                [id]: {
                  ...item,
                  content: content || item?.content,
                  title: title || item?.title,
                  listId: listId || item?.listId,
                  keys: { ...item?.keys || {}, ...keys },
                  updatedDate: new Date(),
                },
              },
            };
          });
        },
        // setActive: (id: uuid, isActive: boolean) => {
        //   set((state) => {
        //     const item = state.items[id];
        //     return {
        //       items: { ...state.items, [id]: { ...item, isActive } },
        //     };
        //   });
        // }
        /// This will set the active item to the given id, and set all other items to inactive
        /// and clear the nextActiveItemId since the activation just completes
        setActiveItemId: (id) => {
          set({ activeItemId: id, nextActiveItemId: null });
        },

        setNextActiveItemId: (id) => {
          set({ nextActiveItemId: id });
        },

        enableGlobalReadOnly: () => {
          set({ isGlobalReadOnly: true });
        },
        disableGlobalReadOnly: () => {
          set({ isGlobalReadOnly: false });
        },
      }),
      {
        name: 'task-item-storage',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
