import React, { ForwardedRef, forwardRef } from 'react';
import * as cn from 'classnames';

import { Handle, Item, Plus, Remove } from '../Item';

import styles from './Container.module.scss';
import EmojiPicker from '../EmojiPicker/EmojiPicker';
import { uuid } from '../../types/types';
// import { ActionDropdown } from '../ActionDropdown/ActionDropdown';
import { getListIds, useTaskListStore } from '../../stores/useTaskListStore';
import { Avatar, Box, Button, IconButton, Card, DropdownMenu, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { DotsHorizontalIcon, PlusCircledIcon, HandIcon } from '@radix-ui/react-icons';
export interface Props {
  id: uuid;
  children: React.ReactNode;
  columns?: number;
  label?: string | React.ReactNode;
  icon?: string;
  iconMutable?: boolean;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  showActions?: boolean;
  showDroppable?: boolean;
  /// if true, the container will be rendered as a button
  useButton?: boolean;
  /// footer
  footer?: React.ReactNode;

  onClick?(event?: React.MouseEvent<HTMLElement>): void;

  onAddNewItemClick?(options?: any): void;

  // onRemove?(): void;
}

const AddNewItem = ({ onAddNewItemClick }: { onAddNewItemClick?: (options: any) => void }) => {
  return (
    <IconButton variant="ghost" className={styles.addNewItemButton} onClick={onAddNewItemClick}>
      <PlusCircledIcon width="18" height="18" />
    </IconButton>
  );
}

/**
 * TODO: move this into a separate component
 */
const ActionDropdown: React.FC = () => {
  // use store to add a new list
  // const addList = useTaskListStore((state) => state.addUserList);
  const userListMap = useTaskListStore((state) => state.userListMap);
  // const userListIds = useTaskListStore((state) => state.userListIds);
  const userListIds = getListIds(userListMap);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.trigger}>
        <IconButton variant="ghost" className={styles.triggerButton}>
          {/* <DropdownMenu.TriggerIcon /> */}
          <DotsHorizontalIcon width="18" height="18" />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.content} align="end">
        {/* <DropdownMenu.Item
          className={styles.item}
          onSelect={() => {
            const lastUserListId = userListIds[userListIds.length - 1];
            const lastCreatedDate = userListMap[lastUserListId].createdDate;
            const tomorrow = moment(lastCreatedDate).add(1, 'day').startOf('day');
            addList('DAY_' + tomorrow.format('YYYYMMDD'), tomorrow);
          }}
        >
          Start a new day
        </DropdownMenu.Item> */}
        {/* <DropdownMenu.Item
          className={styles.item}
          onSelect={() => consoleLog('DrawCard')}
        >
          Draw a card
        </DropdownMenu.Item> */}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const DroppableIndicator: React.FC<{ showDroppable: boolean }> = ({ showDroppable }) => {
  return showDroppable ? <div className={styles.DroppableIndicator}>
    <Box p="2">
      <Card>
        <Flex gap="3" align="center">
          <Avatar
            size="3"
            radius="full"
            fallback={
              <HandIcon />
            }
          />
          <Box>
            <Text as="div" size="2">Drop to place your item here</Text>
          </Box>
        </Flex>
      </Card>
    </Box>
  </div> : null;
};

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      // onRemove,
      onAddNewItemClick,
      label,
      icon,
      iconMutable,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      showActions,
      showDroppable = false,
      useButton,
      footer,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <Flex
        direction="column"
        {...props}
        // @ts-ignore
        ref={ref}
        style={
          {
            ...style,
            '--columns': columns,
          } as React.CSSProperties
        }
        className={cn(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          // scrollable && styles.scrollable,
          shadow && styles.shadow,
          showDroppable && styles.showDroppable,
        )}
        onClick={onClick}
      >
        {label ? (
          <Flex className={styles.Header}
            justify="between"
            align="center"
          >
            <Flex gap="1">
              {icon ? (
                iconMutable ? (
                  <EmojiPicker id={id} emoji={icon} />
                ) : (
                  <span className={styles.Icon}>{icon}</span>
                )
              ) : null}
              {label ? <span className={styles.Label}>{label}</span> : null}
            </Flex>
            {showActions ? (
              <Flex className={styles.Actions} gap="3">
                {/* Remove is for deleting, not needed now */}
                {/* {onRemove ? <Remove onClick={onRemove} /> : null} */}

                {/* Handle is for dragging & moving, not needed now */}
                {/* <Handle {...handleProps} /> */}
                <AddNewItem onAddNewItemClick={onAddNewItemClick} />
                <ActionDropdown />
              </Flex>
            ) : null}
          </Flex>
        ) : null}
        {placeholder ? (
          children
        ) : (
          <Flex className={cn(styles.Body, footer && styles.withFooter)} direction="column" style={{ height: '100%' }}>
            <ScrollArea scrollbars="vertical" type='always'
            >
              <Box p="2">
                <Flex direction="column" gap="3">
                  {children}
                </Flex>
              </Box>
            </ScrollArea>
            <Box className={styles.Footer}>
              {footer ? footer : null}
            </Box>
            <DroppableIndicator showDroppable={showDroppable} />
          </Flex>
        )}
      </Flex >
    );
  }
);
