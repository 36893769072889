import React, { useState } from 'react';
import { Theme, ThemePanel } from '@radix-ui/themes';
import { MultipleContainers } from './widgets/MultipleContainers/MultipleContainers.tsx';

import './App.css';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';

/**
 * Use dynamic import for DebuggerBar
 * This method ensures that the DebuggerBar component and its related code are completely excluded from the production build,
 * thereby reducing the bundle size and improving performance for users who are not in development mode.
 * example: () => import('./widgets/DebuggerBar/DebuggerBar')
 */
const lazyDevOnly = (importPromise: () => Promise<{ default: React.ComponentType }>) => {
  const LazyComponent = process.env.IS_DEV
    ? React.lazy(importPromise)
    : null;

  return LazyComponent ? (
    <React.Suspense fallback={<div>Loading...</div>}>
      <LazyComponent />
    </React.Suspense>
  ) : null;
}

function MyApp() {
  return (
    <div className="App">
      <MultipleContainers
        handle
        // minimal={true}
        vertical={true}
        trashable={false}
        scrollable={true}
      // modifiers={[restrictToFirstScrollableAncestor]}
      />
    </div>
  )
}
function App() {

  return (
    <>
      <Theme accentColor="gray" grayColor="sand" radius="small">
        <MyApp />
        {/* {process.env.IS_DEV && <ThemePanel />} */}
      </Theme>

      {lazyDevOnly(() => import('./widgets/DebuggerBar/DebuggerBar'))}

    </>
  );
}

export default App;
