import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertCodeBlock,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  Separator,
  tablePlugin,
  toolbarPlugin,
  UndoRedo,
  useCellValues,
  usePublisher,
  useRealm,
} from '@mdxeditor/editor';
import React, { useEffect } from 'react';

import { linkDialogState$ } from '@mdxeditor/editor';

import classNames from 'classnames';
import {
  LinkDialogAdvanced,
  LinkDialogAdvancedRef,
} from './LinkDialogAdvanced/LinkDialogAdvanced';
import { CreateLink } from './CreateLink/CreateLink';

// import '@mdxeditor/editor/style.css';
// import '../shared/prose.css';

import styles from './TaskItemMDXEditor.module.css';
import { useTaskItemStore } from '../../stores/useTaskItemStore';
import { uuid } from '../../types/types';
import { consoleLog } from '../../utilities/console';

type Props = {
  value: string;
  placeholder?: string;
  updateContent: (markdown: string) => void;
  updateIsEditing: (isEditing: boolean) => void;
};

export const TaskItemMDXEditor = ({
  value,
  placeholder,
  updateContent,
  updateIsEditing,
}: Props) => {
  consoleLog('TaskItemMDXEditor rendering!');

  const ref = React.useRef<MDXEditorMethods | null>(null);
  const editorContainerRef = React.useRef<HTMLDivElement | null>(null);
  // const toolbarRef = React.useRef<HTMLDivElement | null>(null);
  // const editorlinkDialogAnchor = React.useRef<HTMLDivElement | null>(null);
  // const [isFocused, setIsFocused] = React.useState(false);
  // const [isFocusedDebounced, setIsFocusedDebounced] = React.useState(false);

  /// Click on toolbar and menu items will trigger onBlur but usually
  /// editor will focus back immediately automatically
  /// use a short delay to prevent the editor from focusing back immediately
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsFocusedDebounced(isFocused);
  //   }, 50);
  //   return () => clearTimeout(timeout);
  // }, [isFocused]);

  /*
  useEffect(() => {
    // consoleLog('isFocused:', isFocused);
    // On Focus
    // else {
    // On Blur

    /// Need to add a ending space to the editor, and move the cursor to the end,
    /// so the popover can be hidden
    // ref.current?.setMarkdown(value + ' &#x20;');

    /// Do not immediately hide the dialog, wait for a short while
    /// and if the isFocused is still false, then hide the dialog
    /// This is to prevent the dialog from being hidden when `preview` is switched to `edit`
    const timeout = setTimeout(() => {
      if (!isFocusedDebounced) {
        linkDialogRef.current?.hide();
        // consoleLog('hiding link dialog in TaskItemMDXEditor');
      }
    }, 50);
    return () => clearTimeout(timeout);
    // }
  }, [isFocusedDebounced]);
  */

  /// We don't need to use the hide() method anymore, as the whole Editor will be unmounted
  /// when the editor loses focus. 
  /// linkDialogRef.current?.hide();


  // Move the link dialog anchor under body tag so the popup menu can be correctly positioned
  useEffect(() => {
    const root = editorContainerRef.current?.querySelector(
      'div.mdxeditor'
    ) as HTMLDivElement;
    const target = root.querySelector(
      'div[class*="linkDialogAnchor"]'
    ) as HTMLDivElement;
    if (target) {
      // then move it to the end of the body
      document.body.appendChild(target);
      // editorlinkDialogAnchor.current = target;
      // move it back to the editorContainer
      return () => {
        root.appendChild(target);
      };
    }
  }, []);



  // Given the `ref`, find the only element with class `prose` and 
  // add a focus in and out event listener to it. Instead of using MDXEditor's onBlur, 
  // this way we can include the toolbar clicks.
  useEffect(() => {
    const editorContainer = editorContainerRef.current
    if (editorContainer) {
      const focusoutHandler = () => {
        // setIsFocused(false);
        updateIsEditing(false);
        consoleLog('MDXEditor focusout detected');
      }
      const focusinHandler = () => {
        // setIsFocused(true);
        updateIsEditing(true);
        consoleLog('MDXEditor focusin detected');
      }
      editorContainer.addEventListener('focusin', focusinHandler);
      editorContainer.addEventListener('focusout', focusoutHandler);
      return () => {
        editorContainer.removeEventListener('focusin', focusinHandler);
        editorContainer.removeEventListener('focusout', focusoutHandler);
      };
    }
  }, [editorContainerRef.current]);

  const linkDialogRef = React.useRef<LinkDialogAdvancedRef | null>(null);

  return (
    <div
      ref={editorContainerRef}
      className={classNames(styles.editorContainer,
        // {
        //   [styles.isEditorFocused]: ,
        // }
      )}
    >
      <MDXEditor
        placeholder={placeholder || ''}
        ref={ref}
        className={'editor'}
        contentEditableClassName={'prose'}
        markdown={value === placeholder ? '' : value}
        /// MDXEditor onBlur is very difficult to use, because when the focus jumps from editor to editorBar
        /// or between the floating toolbars, the onBlur event will be triggered. It's hard to reply on this
        /// to control UI changes. 
        // onBlur={() => {
        //   setIsFocused(false);
        //   updateIsEditing(false);
        //   consoleLog('MDXEditor onBlur');
        // }}
        /// Sadly MDXEditor does not support onFocus event.
        // onFocus={() => {
        //   setIsFocused(true);
        //   updateIsEditing(true);
        //   consoleLog('MDXEditor Container onFocus');
        // }}
        autoFocus={{ defaultSelection: 'rootEnd' }}
        onChange={(markdown) => {
          consoleLog('markdown:', markdown);
          // setIsFocused(true);
          updateIsEditing(true);
          updateContent(markdown);
        }}
        plugins={[
          listsPlugin(),
          linkPlugin(),

          linkDialogPlugin({
            LinkDialog: () => {
              // const realm = useRealm()
              // const [linkDialogState] = useCellValues(linkDialogState$);
              // dismissPopup = publishLinkDialogState
              return <LinkDialogAdvanced ref={linkDialogRef} />;
            },
          }),

          // tablePlugin(), // does not work somehow
          quotePlugin(),
          headingsPlugin(),

          // codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
          // codeMirrorPlugin({
          //   codeBlockLanguages: { js: 'JavaScript', css: 'CSS' },
          // }),

          // diffSourcePlugin({diffMarkdown: 'An older version', viewMode: 'rich-text'}),
          // todo-pudu: enable the toolbar when focus within
          toolbarPlugin({
            toolbarContents: () => (
              <div className={styles.editorToobar}>
                {/* <UndoRedo /> */}
                <BoldItalicUnderlineToggles />
                <CodeToggle />
                <Separator />
                <CreateLink />
                <Separator />
                <ListsToggle />
                {/* <InsertCodeBlock /> */}
                {/*<DiffSourceToggleWrapper>*/}
                {/*</DiffSourceToggleWrapper>*/}
              </div>
            ),
          }),

          markdownShortcutPlugin(), // This is essential for the markdown to work, and has to be the last plugin
        ]}
      />

      {/* <button onClick={() => consoleLog(ref.current?.getMarkdown())}>Get markdown</button> */}
      {/* <button onClick={() => ref.current?.setMarkdown('# 123')}>Set new markdown</button> */}
    </div>
  );
};
